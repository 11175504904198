body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    background-color: #282c34;
    text-align: center;
    color: white;
}
.app {
    margin-top: 2em;
}


.app-header {

/*
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.no-margin {
    margin: 0;
}

.App-link {
  color: #61dafb;
}

.container {
    background-color: #282c34;
    min-height: 100vh;
    color: white;
}

.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.chart {
    color: #000000;
}
.m-btm-med {
    margin-bottom: 3em;
}

