.container {
    background-color: #282c34;
    min-height: 100vh;
    color: white;
}

.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
