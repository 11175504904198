body {
    background-color: #282c34;
    text-align: center;
    color: white;
}
.app {
    margin-top: 2em;
}


.app-header {

/*
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.no-margin {
    margin: 0;
}

.App-link {
  color: #61dafb;
}
